<template>
  <div class="col">
    <el-card>
      <el-form
        :model="cms"
        style="width: 100%"
        ref="ruleFormAdd"
        label-position="left"
        @submit.prevent="save"
      >
        <div class="d-flex">
          <el-form-item
            :label="`${localization('Section')}`"
            style="width: 50%"
            class="mr-2"
          >
            <el-input
              type="text"
              v-model="cms.key"
              disabled
              :placeholder="`${localization('Please enter Label')}`"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="`${localization('Language')}`"
            style="width: 50%"
            prop="language"
          >
            <el-select
              v-model="cms.language"
              disabled
              :placeholder="`${localization('Please select language')}`"
              style="width: 100%"
            >
              <el-option
                v-for="(language, i) in $store.getters['cms/languages']"
                :key="i"
                :label="language.title"
                :value="language.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item style="width: 100%" prop="Content">
          <page
            api-key="uwhpqwb5bkozdxtejm40jkuwvcy2u2kp98feem6n6y33fhzu"
            v-model="cms.body"
            :init="init_en"
          />
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="save">{{
        localization("Save")
      }}</el-button>
    </el-card>
  </div>
</template>

<script>
import page from "@tinymce/tinymce-vue";
import Swal from "sweetalert2";

export default {
  name: "edit",
  components: { page },
  data() {
    return {
      init_en: {
        height: 260,
        mode: "none",
        menubar: false,
        dialog_type: "modal",
        importcss_append: true,
        language: "en",
        toolbar:
          "undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | fullscreen  code   | numlist bullist | forecolor backcolor | ltr rtl",
        plugins: [
          "table charmap preview fullscreen importcss textpattern  code  "
        ]
      },
      cms: {}
    };
  },
  computed: {
    languages: function() {
      return this.$store.getters["cms/languages"];
    }
  },
  mounted() {
    this.$store.dispatch("cms/cms", { id: this.$route.params.id }).then(_ => {
      this.cms = this.$store.getters["cms/cms"];
    });
  },
  methods: {
    save() {
      this.$store
        .dispatch("cms/UpdateCMS", {
          id: this.cms.id,
          query: this.cms
        })
        .then(_ => {
          Swal.fire({
            icon: "success",
            title: "",
            text: "Done",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          });
        });
    }
  }
};
</script>

<style scoped></style>
